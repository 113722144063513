<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop='name' label='姓名'>
        <el-input v-model="form.data.name" placeholder="请输入" maxlength="20" clearable></el-input>
      </el-form-item>
      <el-form-item prop='mobile' label='电话'>
        <el-input v-model="form.data.mobile" placeholder="请输入" maxlength="11" clearable :disabled="isChange"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { REG_EXP } from '@/util'
  export default {
    name: 'EnterEdit',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            mobile: '',
          },
          rules: {
            name: [{ required: true, message:'必填项', trigger: 'change' }],
            mobile: [
              { required: true, message:'必填项', trigger: 'change' },
              { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
            ],
          }
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        this.form.data.id = row.id;
        this.form.data.name = row.nickname;
        this.form.data.mobile = row.account;
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let apiUrl = !this.isChange ? '/admin/flex_clerk/add' : '/admin/flex_clerk/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      
    }
  }
</script>

